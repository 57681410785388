import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default class ApikeySecretModel extends Model {
  @attr('string') type;
  @attr('string') expiration;
  @attr('string') value;
  @attr('string') description;

  @computed('jwk', 'value')
  get jwk() {
    try {
      return JSON.parse(this.value);
    } catch (error) {
      console.error(error);
      return this.value;
    }
  }
}
