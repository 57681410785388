import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import arg from '../../../utils/decorators/arg';

export default class LoadersAgreementItemsComponent extends Component {
  @service layout;
  @service store;

  @arg accountId;
  @arg agreementId;
  @arg filter;

  @tracked items = [];

  constructor(...args) {
    super(...args);
    this.getItems.perform();
  }

  @task
  *getItems() {
    const queryOptions = {
      accountId: this.accountId,
      agreementId: this.agreementId,
      $filter: this.filter,
      // $filter: 'MinimumQuantity ge 0'
    };

    if (!this.filter) {
      queryOptions['$filter'] = undefined;
      delete queryOptions['$filter'];
    }

    this.items = yield this.store.query('agreement-product', queryOptions);
  }
}
