import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CreateAccountController extends Controller {
  @service snackbar;
  @service sessionData;
  @service metrics;
  @service leads;
  @service user;

  @task()
  createAccount() {
    try {
      const adapter = this.store.adapterFor('account');
      adapter.create(model);

      this.notify.success('Conta criada com sucesso!');
    } catch (error) {
      let message = 'Erro ao criar conta.';

      if (error.errors.length > 0 && error.errors[0].status === '409')
        message = 'Nome da conta já está sendo utilizado.';

      return this.snackbar.show({ message: message });
    }

    try {
      const currentUser = this.user.getCurrent.perform();
      this.leads.sendLeads(currentUser.name, currentUser.email, currentUser.phone_number, model.name);

      const account = this.sessionData.getCurrentAccount();

      this.metrics.identify({
        distinctId: account.id,
        $email: account.email,
        $name: account.name,
        Environment: account.environment,
      });

      this.transitionToRoute('onboard');
    } catch (error) {
      this.transitionToRoute('onboard');
    }
  }

  @action
  checkAccountName(model, nextStep) {
    let validName = model.validations.attrs.name.isValid;
    if (validName) return nextStep();
  }

  @action
  setPhone() {}
}
