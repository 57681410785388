import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AccountLoaderComponent extends Component {
  @service sessionData;

  @tracked account;

  constructor(...args) {
    super(...args);
    this.loadAccount.perform();
  }

  @task
  *loadAccount() {
    this.account = yield this.sessionData.getCurrentAccount();
    // yield timeout(500);
  }
}
