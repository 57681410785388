import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class AccountsSubscriptionUsageRoute extends BaseAuthenticatedRoute {
  queryParams = ['date'];

  @service store;

  async model({ accountId, subscriptionId, date }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.usage').subscriptionId;

    if (!isPresent(date)) {
      let date = new Date();
      return this.transitionTo(
        'accounts.subscriptions.usage',
        { accountId, subscriptionId },
        { queryParams: { date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}` } }
      );
    }

    if (isPresent(accountId) && isPresent(subscriptionId)) {
      const subscription = await this.store.queryRecord('subscription', { accountId, subscriptionId });
      const subscriptionName = subscription.name;
      return { accountId, subscriptionId, subscriptionName };
    }

    return null;
  }
}
