import BaseAuthenticatedRoute from 'accounts-app/routes/base-authenticated';
import { service } from '@ember/service';

export default class AccountInvoicesRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId }) {
    accountId = accountId ?? this.paramsFor('accounts.invoices').accountId;
    const account = await this.store.findRecord('account', accountId);
    const accountName = account.name;
    return { accountId, accountName };
  }
}
