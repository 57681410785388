import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MainPaymentRoute extends Route {
  @service store;

  async model({ paymentId }) {
    const payment = await this.store.findRecord('payment', paymentId);
    const account = await this.store.findRecord('account', payment.accountId);

    return { payment, account };
  }
}
