import Model, { attr, hasMany } from '@ember-data/model';

import { fragment } from 'ember-data-model-fragments/attributes';
import taxRegimesEnum from 'accounts-app/models/enums/tax-regime';

export default class AccountModel extends Model {
  taxRegimes = taxRegimesEnum.taxRegimesAccount;

  @attr('string') accountId;
  @attr('string') accountNumber;
  @attr('string') createdOn;
  @attr('string') modifiedOn;
  @attr('string') state;
  @attr('string') customerType;
  @attr('string') businessType;
  @attr('string') name;
  @attr('string') emailAddress1;
  @attr('string') emailAddress2;
  @attr('string') emailAddress3;
  @attr('string') telephone1;
  @attr('string') telephone2;
  @attr('string') telephone3;
  @attr('string') formalName;
  @attr('string') taxRegime;
  @attr('string') locale;
  @attr('string') federalTaxNumber;
  @attr('string') municipalTaxNumber;
  @attr('string') regionalTaxNumber;
  @attr('string') description;
  @attr('string') webSiteUrl;

  @fragment('address') address;
  @hasMany('account/contact', { async: false }) contacts;
  @hasMany('subscription', { async: false }) subscriptions;

  get fullAddress() {
    return `${this.address.line1}, ${this.address.line2} - ${this.address.districtOrCounty} - ${this.address.city} - ${this.address.stateOrProvince} - ${this.address.postalCode}`;
  }

  currTaxEnum() {
    return this.taxRegimes.find((regime) => regime.value == this.taxRegime);
  }

  get taxRegimeText() {
    return this.currTaxEnum()?.text;
  }

  get taxRegimeEnum() {
    return this.currTaxEnum() || '';
  }
}
