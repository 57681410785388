import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ApplicationRoute extends Route {
  @service metrics;
  @service router;
  @service user;
  @service session;
  @service sessionData;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', (a) => {
      const page = this.router.currentURL;
      const title = this.router.currentRouteName || 'unknown';
      this.metrics.trackPage({ page, title });
    });
  }

  async beforeModel(transition) {
    await this.session.setup();
    this.user.manageLanguage();
    return super.beforeModel(transition);
  }

  async model() {
    return await this.sessionData.getCurrentAccount();
  }
}
