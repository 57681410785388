import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isBlank } from '@ember/utils';

import arg from '../../../utils/decorators/arg';

export default class LoadersExportComponent extends Component {
  @service store;

  @arg accountId;
  @arg subscriptionId;

  @tracked exportsItems;
  @tracked error = false;

  @task
  *getExport() {
    if (isBlank(this.accountId)) return;
    try {
      this.exportsItems = yield this.store.query('export', {
        adapterOptions: {
          accountId: this.accountId,
          subscriptionId: this.subscriptionId,
        },
      });
      return;
    } catch (error) {
      this.error = true;
    }
  }
}
