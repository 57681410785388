import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class UsersRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId, subscriptionId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.users').subscriptionId;

    if (isPresent(accountId) && isPresent(subscriptionId)) {
      const subscription = await this.store.queryRecord('subscription', { accountId, subscriptionId });
      const subscriptionName = subscription.name;
      return { accountId, subscriptionId, subscriptionName };
    }

    return null;
  }
}
