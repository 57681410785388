import { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';

import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import taxRegimesEnum from '../../models/enums/tax-regime';
import { tracked } from '@glimmer/tracking';
import validadeCnpjCpf from '../../validators/cnpj-cpf';

export default class MainAuthenticatedController extends Controller {
  @service router;
  @service snackbar;
  @service sessionData;

  get taxRegimes() {
    return taxRegimesEnum.taxRegimesAccount;
  }

  @tracked isFormValid = false;

  @tracked
  isInvalid = !this.isValid;

  get isValid() {
    return this.isFormValid && this.isFederalTaxnumberValid && !this.model.contacts.isAny('isValid', false);
  }

  @task
  *updateAccount(model) {

    try {
      yield model.save();
      this.snackbar.show({ message: 'As alterações da conta foram salvas com sucesso!' });
    } catch (error) {
      console.error(error);
    }

    this.router.transitionTo('accounts', this.model.accountNumber);
  }

  get isFederalTaxnumberValid() {
    if (!this.model.federalTaxNumber) return true;
    return validadeCnpjCpf(this.model.federalTaxNumber);
  }

  // get isContactValid() {
  //   console.clear();
  //   return this.model.contacts.isEvery('isValid');
  // }

  @action
  setPhone(contact, { detail }) {
    contact.phone = detail?.value;
  }

  @action
  setFederalTaxNumber({ detail }) {
    this.model.federalTaxNumber = detail?.value;
  }

  @action
  changeRegime({ detail }) {
    this.model.taxRegime = detail?.value;
  }

  @action
  changeFederalTaxNumber(newValue) {
    if (!newValue || !this.isFederalTaxnumberValid) return this.model.federalTaxNumber;
    this.model.federalTaxNumber = newValue;
    return this.model.federalTaxNumber;
  }

  @action
  rollbackChanges() {
    this.model.rollbackAttributes();
    this.router.transitionTo('accounts', this.model.accountNumber);
  }

  @action
  validity(value) {
    this.isFormValid = value;
    this.isInvalid = !this.isValid;
  }
}
