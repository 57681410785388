import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { debug } from '@ember/debug';
import { parseResponse } from 'ember-simple-auth/authenticators/oauth2-implicit-grant';
import { isEmpty } from '@ember/utils';

export default class LoginRoute extends Route {
  @service session;
  @service router;
  @service('implicit-grant-authenticator') authenticateImplicit;

  queryParams = ['redirect'];

  async beforeModel(transition) {
    if (!this.session) return;

    const redirect = transition?.to?.queryParams?.redirect;

    let redirectTo = isEmpty(redirect) ? 'index' : `${window.location.origin}/${redirect}`;

    if (this.session.data && this.session.data.hash) {
      const token = parseResponse(this.session.data.hash);
      await this.session.authenticate('authenticator:oauth2-implicit-grant', token);
      this.session.set('data.hash', null);
    }

    if (this.session.isAuthenticated) {
      // TODO Testar o redirecionamento para o intentUrl
      redirectTo = await this.session.authenticationSucceeded();
    } else if (!this.session.isAuthenticated) {
      const location = this.authenticateImplicit.mountRedirectUrl();
      debug(`replace to: ${location}`);
      return window.location.replace(location);
    }

    debug(`replaceWith to: ${redirectTo}`);
    return this.router.replaceWith(redirectTo);
  }
}
