import Component from '@glimmer/component';
import { action } from '@ember/object';
import arg from '../../utils/decorators/arg';

export default class NfeSideSheetsComponent extends Component {
  @arg title;
  @arg style;

  @action
  closeAction() {
    return (this.args.closed || noOp)();
  }

  @action
  toggleOpen(element) {
    const { open } = this.args;

    if (!open) {
      element.style.transform = `translateX(${this.width})`;
    } else {
      element.style.transform = '';
    }
  }

  get styles() {
    return `width: ${this.width}`;
  }

  get width() {
    return this.args.width || '400px';
  }
}
