import { service } from '@ember/service';
import RESTAdapter from '@ember-data/adapter/rest';
import ENV from 'accounts-app/config/environment';

export default class BaseOdataAdapter extends RESTAdapter {
  @service session;

  host = ENV.APP.ACCOUNTS_API_URL;

  handleResponse(status) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
    return super.handleResponse(...arguments);
  }

  getODataQuery(query) {
    let builders = {
      $filter: this._buildODataFilters(query),
      $orderby: this._buildODataOrderBy(query),
      $skip: this._buildODataSkip(query),
      $top: this._buildODataTop(query),
      $count: this._buildODataCount(query),
      // $select: this._buildODataSelect(query),
      // $expand: this._buildODataExpand(query)
    };

    // TODO: do not use order,expand with count
    let odataArgs = {};
    for (let k in builders) {
      if (builders.hasOwnProperty(k)) {
        let v = builders[k];
        if (v !== null && v !== '') {
          odataArgs[k] = v;
        }
      }
    }

    let customQueryParams = query.customQueryParams || {};
    for (let param in customQueryParams) {
      if (customQueryParams.hasOwnProperty(param)) {
        odataArgs[param] = customQueryParams[param];
      }
    }

    return odataArgs;
  }

  getODataFullUrl(baseUrl, query) {
    let odataArgs = this.getODataQuery(query);
    let queryArgs = [];
    Object.keys(odataArgs).forEach((k) => {
      let v = odataArgs[k];
      if (v) {
        queryArgs.push(`${k}=${v}`);
      }
    });
    let queryMark = queryArgs.length > 0 ? '?' : '';
    let queryPart = queryArgs.join('&');

    return `${baseUrl}${queryMark}${queryPart}`;
  }

  _buildODataOrderBy(query) {
    return query.$orderby;
  }

  _buildODataFilters(query) {
    return query.$filter;
  }

  _buildODataSkip(query) {
    return query.skip;
  }

  _buildODataTop(query) {
    return query.top;
  }

  _buildODataCount(query) {
    return query.count ? true : null;
  }
}
