import Route from '@ember/routing/route';
import { service } from '@ember/service';
import ENV from 'accounts-app/config/environment';

export default class BaseAuthenticatedRoute extends Route {
  @service session;
  @service user;

  async beforeModel(transition) {
    if (ENV.environment.includes('test')) return;
    return this.session.requireAuthentication(transition, 'login');
  }
}
