import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PaymentMethodsController extends Controller {
  @service layout;
  @service store;
  @service sessionData;
  @service('payment-method') paymentMethodService;

  @tracked addCreditCard = false;
  @tracked refresh = false;

  @action
  async changePaymentMethodType(paymentMethod, newPreference) {
    await this.store
      .adapterFor('payment-method')
      .setCardPreference(this.model.accountId, paymentMethod.id, { cardPreference: newPreference.value });
    this.send('refreshRoute');
    this.refresh = !this.refresh;
  }

  @action
  showDialogCreditCard() {
    this.addCreditCard = true;
  }

  @action
  hideDialogCreditCard() {
    this.addCreditCard = false;
  }

  @action
  async savePaymentMethod(model) {
    await this.paymentMethodService.save(model).then(() => {
      this.hideDialogCreditCard();
    });

    this.send('refreshRoute');
    this.refresh = !this.refresh;
  }

  @action
  async removePaymentMethod(paymentMethod) {
    paymentMethod.deleteRecord();
    await paymentMethod.save({ adapterOptions: { accountId: this.model.accountId } });
    this.send('refreshRoute');
    this.refresh = !this.refresh;
  }
}
