import RESTAdapter from '@ember-data/adapter/rest';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { debug } from '@ember/debug';

export default class ApplicationAdapter extends RESTAdapter {
  @service session;
  @service snackbar;
  @service intl;

  async handleResponse(status, headers, payload) {
    if (status < 400) return super.handleResponse(...arguments);

    if (isPresent(payload?.message)) {
      let adapterError = super.handleResponse(...arguments);

      adapterError.code = status;
      adapterError.description = payload.message;

      return adapterError;
    }

    if (status === 401 && this.session.isAuthenticated) {
      await this.session.refreshAuthentication(this.handleReload);
      this.snackbar.show({
        message: this.intl.t('updateToken'),
        leading: true,
        timeout: 5000,
        action: {
          label: this.intl.t('refresh'),
          closing: this.handleReload,
        },
      });
    }
    return super.handleResponse(...arguments);
  }

  handleReload() {
    debug('application-adapter: reload page');
    window.location.reload(true);
  }
}
