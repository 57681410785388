import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SubscriptionViewController extends Controller {
  @service router;
  @service layout;
  @service intl;
  @service sessionData;

  @tracked showContactModal = null;
  @tracked contact = null;

  get shortcuts() {
    return A([
      {
        title: 'Usuários',
        description: 'Gerencie os usuários da assinatura.',
        icon: 'people',
        action: { route: 'accounts.subscriptions.users', label: 'Ver usuários' },
      },
      {
        title: 'Funcionalidades',
        description: 'Recursos disponíveis na aplicação.',
        icon: 'settings_applications',
        action: { route: 'accounts.subscriptions.providers', label: 'Ver Funcionalidades' },
      },
      {
        title: 'Chaves de Acesso',
        description: 'Controle de chaves para autenticação.',
        icon: 'key',
        action: { route: 'accounts.subscriptions.apikeys', label: 'Ver chaves de acesso' },
      },
      {
        title: 'Webhooks',
        description: 'Integrações com outros serviços.',
        icon: 'webhook',
        action: { route: 'accounts.subscriptions.webhooks.index', label: 'Ver webhooks' },
      },
      {
        title: 'Resumo de utilização',
        description: 'Relatório de uso do sistema.',
        icon: 'analytics',
        action: { route: 'accounts.subscriptions.usage', label: 'Ver uso' },
      },
      {
        title: 'Exportações',
        description: 'Exporte dados em vários formatos.',
        icon: 'archive',
        action: { route: 'accounts.subscriptions.export', label: 'Ver exportações' },
      },
    ]);
  }

  get createdOn() {
    return new Date(this.sessionData.subscription.current.createdOn);
  }

  @action
  openContactModal(contact) {
    this.contact = contact;
    this.showContactModal = true;
  }

  @action
  closedContactModal() {
    this.showContactModal = null;
  }

  @action
  transitionToEditAccount() {
    this.router.transitionTo('accounts.edit', this.model.accountNumber);
  }
}
