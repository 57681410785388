import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class AgreementIndexRoute extends BaseAuthenticatedRoute {
  @service router;
  @service store;

  async beforeModel({ accountId }) {
    try {
      accountId = accountId ?? this.paramsFor('accounts.agreements').accountId;
      const agreements = await this.store.query('agreement', { accountId: accountId });

      let currentAgreement = this.hasActiveAgreement(agreements) || agreements.firstObject;
      if (!currentAgreement) return;

      this.router.replaceWith('accounts.agreements.view', accountId, currentAgreement.id);

      return;
    } catch (error) {
      this.error = true;
    }
  }

  hasActiveAgreement(agreements) {
    return agreements.find((agreement) => agreement.status == 'Active');
  }
}
