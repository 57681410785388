import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PaymentInvoicesComponent extends Component {
  @service layout;
  @service store;

  @tracked invoices = [];
  @tracked downloadInvoiceMenuOpened;

  constructor(...args) {
    super(...args);
    this.getPaymentInvoices.perform(this.args.payment);
  }

  @task
  *getPaymentInvoices(payment) {
    const invoicesPromisses = payment.invoices.map(
      async (invoice) =>
        await this.store.queryRecord('invoice', {
          accountId: payment.accountId,
          invoiceId: invoice.id,
        })
    );
    this.invoices = yield all(invoicesPromisses);
  }

  @action
  openDownloadInvoiceMenu(index, ev) {
    ev.stopPropagation();
    this.downloadInvoiceMenuOpened = index;
  }

  @action
  async downloadInvoicePdf(accountId, invoiceId) {
    return await this.store.adapterFor('account').downloadInvoicePdf(accountId, invoiceId);
  }

  @action
  async downloadEInvoice(accountId, invoiceId, type) {
    return await this.store.adapterFor('account').downloadEInvoice(accountId, invoiceId, type);
  }
}
