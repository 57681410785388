import BaseAuthenticatedRoute from 'accounts-app/routes/base-authenticated';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AccountPaymentMethodsRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId }) {
    accountId = accountId ?? this.paramsFor('accounts.payment-methods').accountId;
    const account = await this.store.findRecord('account', accountId);
    const accountName = account.name;
    return { accountId, accountName };
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
