import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import arg from '../../../utils/decorators/arg';

export default class LoadersSubscriptionUsageSummaryComponent extends Component {
  @service store;

  @arg accountId;
  @arg subscriptionId;
  @arg date;

  @tracked usageSummary = [];
  @tracked error = false;

  @task({ drop: true })
  *getUsageSummary() {
    try {
      const dateInstance = new Date(`${this.date} 23:59:59.0000Z`);
      const year = dateInstance.getUTCFullYear();
      const month = dateInstance.getUTCMonth() + 1;
      this.usageSummary = yield this.store.queryRecord('usage-summary', {
        accountId: this.accountId,
        subscriptionId: this.subscriptionId,
        date: { year, month },
      });
      return;
    } catch (error) {
      this.error = true;
    }
  }
}
