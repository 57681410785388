import Service, { service } from '@ember/service';

export default class AddressService extends Service {
  @service store;

  async getCountries() {
    let localCountries = localStorage.getItem('address_countries');

    if (localCountries) return JSON.parse(localCountries);

    try {
      let allCountries = await this.store.findAll('country');

      let countries = allCountries
        .filter((country) => country.fifa)
        .map((item) => {
          return {
            code: item.fifa,
            name: item.portugueseName,
          };
        });

      localStorage.setItem('address_countries', JSON.stringify(countries));

      return countries;
    } catch (error) {
      console.error(error);
    }
  }

  async getStates() {
    let localStates = localStorage.getItem('address_states');

    if (localStates) return JSON.parse(localStates);

    try {
      let allStates = await this.store.findAll('state');

      let states = allStates.map((item) => {
        return {
          code: item.abbreviation,
          name: item.name,
        };
      });

      localStorage.setItem('address_states', JSON.stringify(states));

      return states;
    } catch (error) {
      console.error(error);
    }
  }

  async getCities() {
    const localCities = localStorage.getItem('address_all_cities');

    if (localCities) return JSON.parse(localCities);

    const allCities = await this.store.findAll('city');
    const cities = allCities.map((item) => item.serialize({ includeId: true }));

    localStorage.setItem('address_all_cities', JSON.stringify(cities));

    return cities;
  }

  async getCitiesByState(state) {
    if (!state) return;

    if (state.code) state = state.code;

    const cityKey = `address_${state.toLowerCase()}_cities`;
    const localCitiesByState = localStorage.getItem(cityKey);

    if (localCitiesByState) return JSON.parse(localCitiesByState);

    const allCities = await this.getCities();

    const nomalizedCities = [];
    allCities.forEach((city) => {
      if (city.state === state)
        nomalizedCities.push({
          code: city.id,
          name: city.name,
        });
    });

    localStorage.setItem(cityKey, JSON.stringify(nomalizedCities));

    return nomalizedCities;
  }

  async getAddressByPostalCode(postalCode) {
    if (!postalCode) return null;

    try {
      const addressAdapter = this.store.adapterFor('address');
      let result = await addressAdapter.getAddress(postalCode);
      result.address.street = `${result.address.streetSuffix} ${result.address.street}`.trim();
      return result.address;
    } catch (error) {
      return null;
    }
  }
}
